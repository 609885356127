import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import PlayButtonContainer from 'components/Player/PlayButtonContainer';
import PlayerStateProxy from 'components/Player/PlayerState/PlayerStateProxy';
import styled from '@emotion/styled';

const PlayButton = PlayerStateProxy(PlayButtonContainer);

const PlayButtonWithStyles = styled(PlayButton)(({ theme }) => ({
  height: '7.3rem',
  margin: theme.dimensions.gutter,
  marginLeft: '0',
  verticalAlign: 'middle',
  width: '7.3rem',

  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    fontSize: '1.9rem',
    padding: '1rem',
  },

  svg: {
    fill: theme.colors.black.primary,
  },
}));

export default PlayButtonWithStyles;
