import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const StationDescription = styled('li')(({ theme }) => ({
  color: theme.colors.gray.medium,
  fontSize: theme.fonts.size.small,
  lineHeight: '1.15em',

  a: {
    color: theme.colors.gray.medium,
  },

  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    fontSize: '1.3rem',
    height: 'auto',
    lineHeight: '1.3rem',
  },
}));

export default StationDescription;
