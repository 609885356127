import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const ProfileName = styled('h1')(({ theme }) => ({
  fontSize: theme.fonts.size.large,
  fontWeight: 'bold',
  lineHeight: '1.1em',
  margin: '0',
  paddingRight: '3rem',

  '& > span': {
    paddingRight: '4.6rem',
    position: 'relative',
  },

  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    justifyContent: 'center',
    fontSize: '2.5rem',
    fontWeight: 'bold',
    lineHeight: '1.1em',
    margin: '0',
    maxWidth: 'calc(100% - 3rem)',
    verticalAlign: 'middle',
  },
}));

export default ProfileName;
