import styled from '@emotion/styled';

const ProfileDetails = styled('p')(({ theme }) => ({
  color: theme.colors.gray.medium,
  marginBottom: theme.dimensions.gutter,

  a: {
    color: theme.colors.gray.medium,
  },
}));

export default ProfileDetails;
