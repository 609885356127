import GenericHead from 'components/GenericHead';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { getAlbumUrl, getArtistUrl, join as joinURL } from 'utils/url';
import { Helmet } from 'react-helmet';
import {
  metaResolveUrl as resolveUrl,
  translateKeywords,
} from 'utils/metaUtils';

type Props = {
  albumId: number;
  albumName: string;
  artistId: number;
  artistName: string;
  duration: number;
  imageUrl: string;
  lyrics: string;
  name: string;
  pagePath: string;
  siteUrl: string;
  trackId: number;
  trackNumber: number;
};

function Head({
  albumId,
  albumName,
  artistId,
  artistName,
  duration,
  imageUrl,
  lyrics,
  name,
  pagePath,
  siteUrl,
  trackId,
  trackNumber,
}: Props) {
  const translate = useTranslate();

  const keywords = `${name}, ${artistName}, ${translateKeywords(
    translate,
    'Lyrics, Music, New, Best, Station, Listen, Play, Similar, iHeartRadio, iHeart, Radio',
  )}`;
  const albumUrl = getAlbumUrl(artistId, artistName, albumId, albumName);
  const artistUrl = getArtistUrl(artistId, artistName);
  const title = `${artistName || name || ''}${
    artistName && name ? ` - ${name}` : ''
  }`;
  const trackUrl = resolveUrl(siteUrl, pagePath);

  // IHRWEB-15991: The lyrics being passed into the metatag description need to have the linebreaks separated by
  // commas and these chain string replacements account for all edge cases of the lyrics string lay out
  const description =
    lyrics.replace(/\n\n/g, ', ').replace(/,\n/g, ' ').replace(/\n/g, ', ') ||
    undefined;

  return (
    <>
      <GenericHead
        deeplink={`goto/custom/track/${trackId}`}
        description={description}
        image={imageUrl}
        legacyDeeplink={`play/custom/track/${trackId}`}
        ogType="music.song"
        title={title}
        twitterCard="summary_large_image"
      />
      <Helmet
        meta={[
          { content: 'noindex, nofollow', name: 'robots' },
          { content: keywords, name: 'keywords' },
          { content: 'audio/vnd.facebook.bridge', property: 'og:audio:type' },
          { content: trackUrl, property: 'og:audio' },
          { content: joinURL(siteUrl, artistUrl!), property: 'music:musician' },
          { content: joinURL(siteUrl, albumUrl!), property: 'music:album' },
          {
            content: trackNumber as any as string,
            property: 'music:album:track',
          },
          { content: duration as any as string, property: 'music:duration' },
        ]}
      />
    </>
  );
}

export default Head;
