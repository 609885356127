import Section from 'components/Section';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { ReactElement } from 'react';

type Props = {
  lyrics: string;
};

function Lyrics({ lyrics }: Props) {
  const translate = useTranslate();

  if (!lyrics) return null;

  return (
    <Section header={translate('Lyrics')} isHidden={!lyrics}>
      <p css={{ userSelect: 'none' }}>
        {lyrics.split(/\r\n|\r|\n/gim).reduce(
          (withLineBreaks, line, index) =>
            index === 0 ?
              [line]
              // eslint-disable-next-line react/no-array-index-key
            : [...withLineBreaks, <br key={index} />, line],
          [] as Array<ReactElement<Record<string, unknown>> | string>,
        )}
      </p>
    </Section>
  );
}

export default Lyrics;
