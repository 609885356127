import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const StationName = styled('li')(({ theme }) => ({
  ...theme.mixins.ellipsis,
  display: 'inline-block',
  fontSize: '1.6rem',
  fontWeight: 'bold',
  lineHeight: '2rem',
  maxWidth: '100%',
  paddingRight: '4rem',
  position: 'relative',

  [mediaQueryBuilder(theme.mediaQueries.min.width['768'])]: {
    fontSize: '1.9rem',
    lineHeight: '2.2rem',
  },
}));

export default StationName;
