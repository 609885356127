import ExplicitLyrics from 'components/ExplicitLyrics';
import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const SongPageExplicit = styled(ExplicitLyrics)(({ theme }) => ({
  position: 'absolute',
  right: '0',
  top: '1.3rem',

  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    top: '1rem',
  },
}));

export default SongPageExplicit;
