import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const StationMeta = styled('ul')(({ theme }) => ({
  ...theme.mixins.ulReset,
  display: 'inline-block',
  listStyle: 'none',
  margin: 0,
  maxWidth: '100%',
  padding: 0,
  verticalAlign: 'middle',
  zoom: 1,

  [mediaQueryBuilder(theme.mediaQueries.min.width['768'])]: {
    width: '74%',
  },
}));

export default StationMeta;
