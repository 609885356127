import SongPlayer from './SongPlayer';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  getCurrentArtistId,
  getCurrentArtistName,
} from 'state/Artists/selectors';
import {
  getCurrentTrackAlbumName,
  getCurrentTrackDuration,
  getCurrentTrackId,
  getCurrentTrackImage,
  getCurrentTrackIsExplicit,
  getCurrentTrackTitle,
} from 'state/Tracks/selectors';
import {
  getCustomRadioEnabled,
  getOnDemandEnabled,
} from 'state/Features/selectors';

const mapStateToProps = createStructuredSelector({
  albumName: getCurrentTrackAlbumName,
  artistId: getCurrentArtistId,
  artistName: getCurrentArtistName,
  customRadioEnabled: getCustomRadioEnabled,
  duration: getCurrentTrackDuration,
  explicit: getCurrentTrackIsExplicit,
  imageUrl: getCurrentTrackImage,
  name: getCurrentTrackTitle,
  onDemandEnabled: getOnDemandEnabled,
  trackId: getCurrentTrackId,
});

export default connect(mapStateToProps)(SongPlayer);
