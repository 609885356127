import styled from '@emotion/styled';

const StationInfo = styled('div')(({ theme }) => ({
  display: 'inline-block',
  margin: `${parseInt('1.5', 10) / 2}rem 0`,
  padding: `1.2rem 0 0 ${theme.dimensions.gutter}`,
  width: `calc(76% - ${theme.dimensions.gutter})`,

  '.cta': {
    float: 'right',
    margin: `0 ${theme.dimensions.gutter} 0 0`,
    maxWidth: '13rem', // let's set a cap on the cta width to not break the layout

    '.btn': {
      lineHeight: '4.1rem',
      minWidth: '11.8rem',
    },
  },
}));

export default StationInfo;
