import styled from '@emotion/styled';

const ProfileInfo = styled('div')({
  display: 'inline-block',
  padding: '0',
  position: 'relative',
  textAlign: 'left',
  width: '100%',
});

export default ProfileInfo;
