import ArtistSongHead from './ArtistSongHead';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  getCurrentArtistId,
  getCurrentArtistName,
} from 'state/Artists/selectors';
import {
  getCurrentTrackAlbumId,
  getCurrentTrackAlbumName,
  getCurrentTrackDuration,
  getCurrentTrackId,
  getCurrentTrackImage,
  getCurrentTrackLyrics,
  getCurrentTrackNumber,
  getCurrentTrackTitle,
  getSongPath,
} from 'state/Tracks/selectors';
import { getSiteUrl } from 'state/Config/selectors';

const mapStateToProps = createStructuredSelector({
  albumId: getCurrentTrackAlbumId,
  albumName: getCurrentTrackAlbumName,
  artistId: getCurrentArtistId,
  artistName: getCurrentArtistName,
  duration: getCurrentTrackDuration,
  imageUrl: getCurrentTrackImage,
  lyrics: getCurrentTrackLyrics,
  name: getCurrentTrackTitle,
  pagePath: getSongPath,
  siteUrl: getSiteUrl,
  trackId: getCurrentTrackId,
  trackNumber: getCurrentTrackNumber,
});

export default connect(mapStateToProps)(ArtistSongHead);
