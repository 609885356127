import styled from '@emotion/styled';

const ProfileArtWrapper = styled('div')(({ theme }) => ({
  display: 'inline-block',
  margin: 0,
  marginRight: theme.dimensions.gutter,
  paddingTop: '1.2rem',
  textAlign: 'right',
  width: '24%',
}));

export default ProfileArtWrapper;
